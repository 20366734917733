<template>
  <div class="is-flex is-justify-content-center is-flex-direction-column">
    <audio
      v-if="base64 && !isRecording"
      :src="base64"
      controls="controls"
      class="mb-1"
      style="width: 100%;"
    >
      <source
        style="display: block;margin-left: auto;margin-right: auto;"
        type="audio/wav"
      >
    </audio>
    <button
      @click="startStopRecording"
      class="button is-primary has-text-weight-semibold is-fullwidth"
      :class="{
        'is-danger': isRecording
      }"
    >
      <i
        class="fas fa-microphone-alt mr-2"
        :class="{
          'microphone-recording': isRecording
        }"
      ></i>
      {{ !isRecording ? (!blob ? 'Start recording' : 'Restart recording')  : 'Recording...click to stop'}}
    </button>
  </div>
</template>

<script>
import Recorder from 'audio-recorder-js'

export default {
  name: 'AudioInput',
  props: [],
  data () {
    return {
      audioContext: null,
      recorder: null,
      isRecording: false,
      blob: null,
      base64: null
    }
  },
  methods: {
    async setup() {
      this.audioContext =  new (window.AudioContext || window.webkitAudioContext)()
      this.recorder = new Recorder(this.audioContext, {
        // An array of 255 Numbers
        // You can use this to visualize the audio stream
        // If you use react, check out react-wave-stream
        onAnalysed: data => data,
        numChannels: 2,
        sampleRate: 44100,
        sampleBits: 16,  // 8 or 16
        bufferLen: 4096,
        mimeType:'audio/wav'
      })

      await navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => this.recorder.init(stream))
        .catch(err => console.log('Uh oh... unable to get stream...', err))
    },
    async startStopRecording() {
      if (!this.audioContext) {
        await this.setup()
      }

      if (!this.isRecording) {
        this.$emit('audioRecorded', null)
        this.recorder.start()
          .then(() => this.isRecording = true)
      } else {
        this.isRecording = false
        this.recorder.stop()
          .then(({blob, buffer}) => {
            this.blob = blob;
            this.$emit('audioRecorded', blob)

            const reader = new FileReader()
            reader.onload = e => {
              this.base64 = e.target.result
            }
            reader.readAsDataURL(blob)
          })
      }
    },
    download() {
      Recorder.download(this.blob, 'my-audio-file'); // downloads a .wav file
    }
  }
}

</script>

<style>
.microphone-recording {
  animation: beat .25s infinite alternate;
	transform-origin: center;
}

@keyframes beat {
	to { transform: scale(1.4); }
}
</style>